import React from "react";

// components
import Overview from "../about-us/Overview";
import Mission from "../about-us/Mission";
import Companies from "../about-us/Companies";
import Numbers from "../about-us/Numbers";
import Leadership from "../about-us/Leadership";
import MainPage from "../main/MainPage";

const AboutUsWrapper = () => {
  return (
    <>
      <MainPage />
      <Overview />
      <Mission />
      <Companies />
      <Numbers />
      {/* <Leadership /> */}
    </>
  );
};

export default AboutUsWrapper;
