import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// css
import "./WhatWeDo.css";

// assets
import scasImg from "../../assets/images/SCAS-IMG.png";
import img1 from "../../assets/images/sales-force-productivity.png";
import img2 from "../../assets/images/fulfillment-management.png";
import img3 from "../../assets/images/training-education.png";
import img4 from "../../assets/images/online-data-vault.png";
import img5 from "../../assets/images/expense-management.png";
import img6 from "../../assets/images/private-placement-generator.png";
import posterImg from "../../assets/images/SCAS.jpg";
import headerVideo from "../../assets/videos/SCAS.mp4";
import mobileHeaderImg from "../../assets/images/SCAS-MOBILE.jpg";

const TechnologyOperations = () => {
  // settings for the react slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <>
      <section className="wwd-overview" id="investment-management">
        {/* <div class="main-padding wwd-overview-container">
          <div className="main-header-margin">
            <h1 className="main-header">StratCap Advisory Services</h1>
          </div>
        </div> */}
        {/* TEST START */}
        <div className="video-header-container">
          <div className="video-header-content-container">
            {screenSize.dynamicWidth < 665 ? (
              <div>
                <img
                  src={mobileHeaderImg}
                  alt="stratcap advisory services header"
                  className="mobile-header-img"
                />
                <div className="header-video-text-overlay main-margin">
                  <div className="main-header-margin">
                    <h1 className="header-video-overlay-text">
                      StratCap Advisory Services
                    </h1>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  poster={posterImg}
                  id="header-video"
                >
                  <source src={headerVideo} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                <div className="header-video-text-overlay main-margin">
                  <div className="main-header-margin">
                    <h1 className="header-video-overlay-text">
                      StratCap Advisory Services
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* TEST END */}
        <div class="main-padding wwd-solutions-container">
          <div class="main-margin negative-margin">
            <p className="t-left investment-management-card">
              StratCap Advisory Services is a specialized technology company
              that focuses exclusively on maximizing the efficiency of financial
              services wholesale distribution businesses. Our proprietary
              technology platform is designed to help your organization analyze
              key distribution data to accomplish their highest impact
              objectives: drive deeper understanding, facilitate better decision
              making, enhance engagement with your selling group and increase
              sales.
            </p>
          </div>
          <div className="main-margin wwd-solutions-main-wrapper">
            <div className="wwd-solutions-content-wrapper">
              <h1>Our Proprietary Technology ​</h1>
              <p>
                StratCap Advisory Services’ technology platform utilizes a
                highly customized distribution analytics engine to boost sales
                force effectiveness, increase firm penetration, reduce expenses
                and drive profitable growth.
              </p>
              <p>
                Through a series of proprietary mission critical applications,
                we have developed a system for optimizing all key wholesale
                distribution metrics.
              </p>
              {/* links to institutional team on contacts page, or opens general email to someone in the institutional team */}
              <div className="is-learn-button-wrapper">
                <Link to="/contact-us" className="link-button">
                  Contact Us to Learn More
                </Link>
              </div>
            </div>
            <div className="solutions-content-img">
              <img src={scasImg} alt="" />
            </div>
          </div>
          <div class="main-margin">
            {screenSize.dynamicWidth < 780 ? (
              <Slider {...settings}>
                {/* mobile start */}
                <div className="techops-grid-item">
                  <img src={img1} alt="Wholesale Distribution" />
                  <h3>
                    Sales Force <br />
                    Productivity
                  </h3>
                  <p>
                    Utilize sophisticated analytics to understand how specific
                    sales and marketing activities impact product distribution
                    strategies.
                  </p>
                </div>
                <div className="techops-grid-item">
                  <img src={img2} alt="Compliance & Legal" />
                  <h3>
                    Fullfilment
                    <br />
                    Management
                  </h3>
                  <p>
                    Rules-driven, online ordering system that manages all
                    aspects of fulfillment to effectuate compliance, reduce
                    costs and drive greater ROI.
                  </p>
                </div>
                <div className="techops-grid-item">
                  <img src={img3} alt="Sales, Service & Support" />
                  <h3>
                    Training
                    <br />& Education
                  </h3>
                  <p>
                    Provides 24/7 access to a dynamic knowledge center with
                    access to videos, webinars, research, fund updates, and
                    product/sales training content.
                  </p>
                </div>
                <div className="techops-grid-item">
                  <img src={img5} alt="Due Diligence" />
                  <h3>
                    Expense
                    <br />
                    Management
                  </h3>
                  <p>
                    Effectively analyze all aspects of distribution expenses to
                    manage and determine relationship profitability.
                  </p>
                </div>
                <div className="techops-grid-item">
                  <img src={img4} alt="Operations" />
                  <h3>
                    Online
                    <br />
                    Data Vault
                  </h3>
                  <p>
                    Designed to facilitate research and simplify the product
                    approval process for designated due diligence personnel.
                    Provides approved users with a guided process from fund
                    overview and investment information to electronic execution
                    of selling agreements.
                  </p>
                </div>
                <div className="techops-grid-item">
                  <img src={img6} alt="Marketing & Research" />
                  <h3>
                    Private Placement
                    <br />
                    Generator
                  </h3>
                  <p>
                    Proprietary Private Placement Generator allows Advisors,
                    Sponsors, and Distributors to instantly generate Private
                    Placement Memorandum, track and deliver them electronically
                    and monitor conversion ratios with automatic supplement
                    updates.
                  </p>
                </div>
                {/* mobile end */}
              </Slider>
            ) : (
              <Slider {...settings}>
                {/* first start */}
                <div className="test-grid">
                  <div className="techops-grid-item">
                    <img src={img1} alt="Wholesale Distribution" />
                    <h3>
                      Sales Force <br />
                      Productivity
                    </h3>
                    <p>
                      Utilize sophisticated analytics to understand how specific
                      sales and marketing activities impact product distribution
                      strategies.
                    </p>
                  </div>
                  <div class="vertical-line"></div>
                  <div className="techops-grid-item">
                    <img src={img2} alt="Compliance & Legal" />
                    <h3>
                      Fullfilment
                      <br />
                      Management
                    </h3>
                    <p>
                      Rules-driven, online ordering system that manages all
                      aspects of fulfillment to effectuate compliance, reduce
                      costs and drive greater ROI.
                    </p>
                  </div>
                  <div class="vertical-line"></div>
                  <div className="techops-grid-item">
                    <img src={img3} alt="Sales, Service & Support" />
                    <h3>
                      Training
                      <br />& Education
                    </h3>
                    <p>
                      Provides 24/7 access to a dynamic knowledge center with
                      access to videos, webinars, research, fund updates, and
                      product/sales training content.
                    </p>
                  </div>
                </div>
                {/* first end */}
                {/* second start */}
                <div className="test-grid">
                  <div className="techops-grid-item">
                    <img src={img5} alt="Due Diligence" />
                    <h3>
                      Expense
                      <br />
                      Management
                    </h3>
                    <p>
                      Effectively analyze all aspects of distribution expenses
                      to manage and determine relationship profitability.
                    </p>
                  </div>
                  <div class="vertical-line"></div>
                  <div className="techops-grid-item">
                    <img src={img4} alt="Operations" />
                    <h3>
                      Online
                      <br />
                      Data Vault
                    </h3>
                    <p>
                      Designed to facilitate research and simplify the product
                      approval process for designated due diligence personnel.
                      Provides approved users with a guided process from fund
                      overview and investment information to electronic
                      execution of selling agreements.
                    </p>
                  </div>
                  <div class="vertical-line"></div>
                  <div className="techops-grid-item">
                    <img src={img6} alt="Marketing & Research" />
                    <h3>
                      Private Placement
                      <br />
                      Generator
                    </h3>
                    <p>
                      Proprietary Private Placement Generator allows Advisors,
                      Sponsors, and Distributors to instantly generate Private
                      Placement Memorandum, track and deliver them
                      electronically and monitor conversion ratios with
                      automatic supplement updates.
                    </p>
                  </div>
                </div>
                {/* second end */}
              </Slider>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologyOperations;
