import React from "react";

// css
import "./CustomModal.css";

// components
import PP from "../footer/PP";
import TOU from "../footer/TOU";
import BCP from "../footer/BCP"

const CustomModal = (props) => {
  const { type, show, hide } = props;

  return (
    <div className={show ? "cust-modal-show" : "cust-modal-hide"}>
      <div className="cust-modal-content">
        <span className="cust-modal-close" onClick={hide}>
          x
        </span>
        {type === "PP" ? <PP /> : null}
        {type === "BCP" ? <BCP /> : null}
        {type === "TOU" ? <TOU /> : null}
        <div className="modal-bottom-close">
          <button onClick={hide}>CLOSE</button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
