import React from "react";

const BCP = () => {
  return (
    <>
      <div className="text-modal-wrapper">
        {/* <p className="t-right">LAST UPDATED: March 1 2022</p> */}
        <p style={{fontSize: "14px"}}>StratCap Securities, LLC Business Continuity Plan</p>
        <p>
          StratCap Securities, LLC has developed a Business Continuity Plan on
          how we will respond to events that significantly disrupt our business.
          Since the timing and impact of disasters and disruptions is
          unpredictable, we will have to be flexible in responding to actual
          events as they occur. With that in mind, we are providing you with
          this information on our business continuity plan.
        </p>
        <p>
          Contacting Us- If you cannot access us through either phone or e-mail,
          you should contact Sponsor/Fund, or the broker/dealer through which
          you have invested in directly for instructions on how you may access
          investment information, invest additional funds and or provide you
          with access to your funds as provided for in the subscription
          documentation.
        </p>
        <p>
          Our Business Continuity Plan- We plan to quickly recover and resume
          business operations after a significant business disruption and
          respond by safeguarding our employees and property, making a financial
          and operational assessment, protecting the firm’s books and records,
          and allowing our customers to transact business. In short, our
          business continuity plan is designed to permit our firm to resume
          operations as quickly as possible, given the scope and severity of the
          significant business distribution. Our business continuity plan
          addresses: data backup and recovery; all mission critical systems;
          financial and operational assessments; alternative communications with
          customers, employees, and regulators; alternate physical locations of
          employees; critical supplier, contractor, bank and counter-party
          impact; regulatory reporting; and assuring our customers prompt access
          to their funds and securities if we are unable to continue our
          business. Since you invest directly, the Funds, through their transfer
          agent, are responsible for those records that they maintain on your
          behalf as an investor in their investment program. Additionally, when
          you invest with our assistance, we take steps to assure that you have
          paper or electronic copies of everything that you have signed and or
          obligated yourself to, which, results in your having access to
          essentially the same records as us, should you be unable to contact us
          during an emergency. Finally, while most transactions done through us
          are not time sensitive, every emergency poses unique problems based on
          external factors, such as time of day and the severity of the
          disruption. Thus, while the Fund(s) desires to restore its own
          operations and be able to complete existing transactions and accept
          new transaction and payments within a short period of time, your
          orders and requests for funds and securities could be delayed during
          this period by them.
        </p>
        <p>
          Varying Disruptions- Significant business disruptions can vary in
          their scope, such as only our firm, a single building housing our
          firm, the business district where our firm is located, the city where
          we are located, or the whole region. Within each of these areas, the
          severity of the disruption can also vary from minimal to severe. In
          disruption to only our firm or a building housing our firm, we will
          transfer our operations to a local site when needed and expect to
          recover and resume business within forty-eight (48) to seventy-two
          (72) hours. In a disruption affecting our business district, city, or
          region, we will transfer our operations to a site outside of the
          affected area and recover and resume business within seventy-two (72)
          hours. In either situation, we plan to continue in business, and
          notify you through our customer emergency number how to contact us. If
          the significant business disruption is so severe that it prevents us
          from remaining in business, we will take steps to assure your prompt
          interface with the Fund(s).
        </p>
        <p>
          For more information- If you have questions about our business
          continuity planning, you can contact us at 949-432-9465. This plan is
          subject to modification; an updated summary will be promptly posted on
          our Web site and may alternatively be obtained by requesting a written
          copy by mail.
        </p>
      </div>
    </>
  );
};

export default BCP;
