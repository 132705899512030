import React from "react";

// css
import "./WhatWeDo.css";

const Wireless = () => {
  return (
    <section className="wwd-wireless" id="wireless-infrastructure">
      <div class="main-padding wwd-wireless-container">
        <div class="main-margin wwd-wireless-main-wrapper">
          <div className="main-header-margin">
            <h1 className="main-header wireless-header">Wireless Infrastructure</h1>
            <p className="t-left">
              More and more connected devices, the continued expansion of 5G and
              our increasing reliance on wireless communication are major
              influences fueling the demand for wireless infrastructure assets
              like cell towers, fiber networks, digital exchange centers, and
              more.
            </p>
          </div>
        </div>
      </div>
      <div class="main-padding wwd-wireless-bottom-wrapper">
        <div class="main-margin wwd-wireless-content-wrapper">
          <div class="sector-focus-card-grid">
            <div className="sector-focus-grid-card">
              <div class="sector-focus-card-inside">
                <h1>Connectivity</h1>
                <p>
                  Reliable connectivity without restriction – all the time, at
                  full speed, on any device, from anywhere – has become the
                  expectation in our connected world.
                </p>
              </div>
            </div>
            <div className="sector-focus-grid-card">
              <div class="sector-focus-card-inside">
                <h1>Strategy</h1>
                <p>
                  We expect to capitalize on the ongoing demand for wireless
                  communications and connectivity by acquiring these critical
                  wireless infrastructure assets as well as dynamic
                  telecom-related operating businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wireless;
