import React from "react";

// css
import "./WhatWeDo.css";

const DigitalMobility = () => {
  return (
    <section className="wwd-dm" id="digital-mobility">
      <div class="main-padding wwd-dm-container">
        <div class="main-margin wwd-dm-main-wrapper">
          <div className="main-header-margin">
            <h1 className="main-header">Digital Mobility</h1>
            <p className="t-left">
              Climate change initiatives, consumer preference and legislative
              mandates have nearly every major automaker and original equipment
              manufacturer committed to decarbonization and a future with no
              emissions.
            </p>
          </div>
        </div>
      </div>
      <div class="main-padding wwd-dm-bottom-wrapper">
        <div class="main-margin wwd-dm-content-wrapper">
          <div class="sector-focus-card-grid">
            <div className="sector-focus-grid-card">
              <div class="sector-focus-card-inside">
                <h1>Next-Generation Transportation</h1>
                <p>
                  The transportation industry has been expanding for years and
                  attracting capital at an accelerating pace with key
                  stakeholders poised to drive transformative change and usher
                  in the era of zero emission vehicles.
                </p>
              </div>
            </div>
            <div className="sector-focus-grid-card">
              <div class="sector-focus-card-inside">
                <h1>Investment Opportunities</h1>
                <p>
                  While the sector is experiencing momentous growth, it is still
                  in its infancy and we believe there are significant investment
                  opportunities in commercial and consumer electric vehicle (EV)
                  manufacturers, battery technologies, charging technologies,
                  clean power and component manufacturers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DigitalMobility;
