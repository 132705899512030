import React from "react";

// components
import Wireless from "../what-we-do/Wireless";
import DataCenter from "../what-we-do/DataCenter";
import DigitalMobility from "../what-we-do/DigitalMobility";

const WhatWeDoWrapper = () => {
  return (
    <>
      <Wireless />
      <DataCenter />
      <DigitalMobility />
    </>
  );
};

export default WhatWeDoWrapper;
