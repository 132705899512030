import React from "react";

const TOU = () => {
  return (
    <>
      <div class="text-modal-wrapper">
        <p class="t-right">As of: March 1, 2022</p>
        <h3>SECURITIES LAW AND OFFERING</h3>
        <p>
          The Website may contain forward-looking statements, within the meaning
          of Section 27A of the Securities Act of 1933 and 21E of the Securities
          Exchange Act of 1934, including discussion and analysis of the
          financial condition of the certain issuers of securities, anticipated
          capital expenditures required to complete certain projects, amounts of
          anticipated cash distributions to investors in the future and certain
          other matters. Readers of the Website should be aware that there are
          various factors that could cause actual results to differ materially
          from any such forward-looking statements, which include changes in
          general economic conditions, changes in real estate conditions,
          construction costs which may exceed estimates, construction delays,
          increases in interest rates, lease-up risks, inability to obtain new
          tenants to fill vacant of vacated space, and the potential need to
          fund tenant improvements or other capital expenditures out of
          operating cash flow in excess of any reserves therefore.
        </p>
        <h3>NOT AN OFFER TO SELL SECURITIES</h3>
        <p>
          The information and material offered on or through the Website does
          not constitute an offer to sell, nor a solicitation of an offer to
          buy, the securities described herein. Such an offering is made only by
          means of a prospectus. This information and material must be read in
          conjunction with the prospectus in order to understand fully all the
          implications and risks of any offering of securities to which it
          relates
        </p>
        <h3>WEBSITE TERMS AND CONDITIONS</h3>
        <p>
          Please read these Terms and Conditions carefully before you start to
          use the Website. By using the Website you accept and agree to be bound
          and abide by these Terms and Conditions. If you do not want to agree
          to these Terms and Conditions, you must not use the Website.
          Furthermore, by clicking “I Accept”, “I Agree”, or words of similar
          import on the click-through page of an offering, you represent that
          you have been provided with the applicable prospectus and have had an
          opportunity to review these Terms and Conditions and any other
          applicable terms and conditions made available to you, as well as risk
          factors of said offering.
        </p>
        <p>
          StratCap (“we”, “us”, or “our”) makes the Website available to you for
          your information and as background for our products and services and
          our parent companies, subsidiaries, affiliates, and affiliated funds
          (collectively, the “Affiliated Entities”), subject to the following
          terms and conditions, together with any other documents and terms and
          conditions they expressly incorporate by reference (collectively,
          these “Terms and Conditions”). These Terms and Conditions govern your
          access to and use of the Website, including any Content offered on or
          through the Website, whether you are a guest or a registered user of
          the Website.
        </p>
        <p>
          We offer the Website as a convenience to: (1) users of the Website;
          (2) a variety of legally and financially separate entities that make
          up the Affiliated Entities which may be either parent companies or
          subsidiaries of the same entities, affiliates of each other, or share
          common ownership; and (3) legally and financially separate entities
          that (i) were or are sponsored or advised by one of the Affiliated
          Entities, or (ii) have some common ownership with one or more of the
          Affiliated Entities that from time to time may maintain business
          relations with any of the Affiliated Entities. Each of the Affiliated
          Entities and each other entity described above is a separate legal
          entity and no legal or financial relationship is to be implied between
          or among any entities on the Website within the Affiliated Entities or
          such other entities. By accessing or using the website made available
          at www.stratcap.com (“Website”) or any of the Content offered on or
          through the Website and other domains owned, registered, or held and
          used by or on behalf of Affiliated Entities, you agree on behalf of
          yourself, and any and all persons, companies, and other legal entities
          (“your principals”) that you represent, if any, and any and all
          employees and agents thereof (collectively, “you” and “your”) that you
          have read and agree to use the Website in accordance with these Terms
          and Conditions.
        </p>
        <p>
          Your continued use of the Website means that you agree that these
          Terms and Conditions legally bind you in the same manner that a
          signed, written, non-electronic contract does. You should not use the
          Website in any manner or attempt to access the Website or any
          individual Content provided therein if you are not willing to be bound
          and abide by these Terms and Conditions. Your continued use of the
          Website also means that you represent and warrant that you are able to
          enter into legally binding contracts and that you are authorized by
          your principals, if any, to: (1) use the Website; and (2) agree to
          these Terms and Conditions as a legally binding contract on behalf of
          you and your company (or legal entities, as applicable).
        </p>
        <p>
          For purposes of these Terms and Conditions, the term “Website”
          includes without limitation those parts of the Website that you can
          access based on your use of or account with the Website, if any,
          including without limitation the publicly available content,
          materials, and information, and any portion thereof, and any other
          functionality, services, content, information, or other materials that
          are provided or may be available to you on or through the Website
          (collectively, “Content”).
        </p>
        <h3>1. LICENSE TO THE CONTENT</h3>
        <p>
          Subject to your compliance with these Terms and Conditions, we grant
          you a limited, nonexclusive, nontransferable license during the Term
          to access the Website solely to display the Content for informational
          purposes only and to print and/or save copies of the Content with or
          on your personal computer, solely for your use in obtaining
          information regarding the Affiliated Entities. Any other use of the
          Content or the Website is expressly prohibited. We (and our licensors)
          reserve all other rights in the Content and the Website. You agree
          these Terms and Conditions do not grant you any rights in or licenses
          to the Website, except for the express, limited license set forth in
          this Section. You will not otherwise copy, transmit, distribute, sell,
          license, de-compile, reverse engineer, disassemble, modify, publish,
          participate in the transfer or sale of, create derivative works from,
          perform, display, incorporate into another website, or in any other
          way use or exploit any of the Content or any other part of the Website
          or any derivative works thereof, in whole or in part, for commercial
          or non-commercial purposes without our express prior written
          permission. Without limiting the foregoing, you will not frame or
          display the Website (or any part of the Website) as part of any other
          website or any other work of authorship without our express prior
          written permission.
        </p>
        <p>
          You may need to register to use all or part of the Website. We may
          reject, or require that you change, any user name, password, or other
          information that you provide to us in registering. Your user name and
          password are for your personal use only and should be kept
          confidential; you are responsible for any use or misuse of your user
          name or password, and you must promptly notify us of any
          confidentiality breach or unauthorized use of your user name or
          password, or your Website account. We may make certain material
          available to you through a “log in” (or similar) section of the
          Website; any such material is intended for broker-dealer use only and
          shall not be provided to potential clients unless otherwise expressly
          identified as “for Client Use”.
        </p>
        <h3>2. PRODUCTS AND SERVICES</h3>
        <p>
          The information regarding the Affiliated Entities provided on the
          Website may change at any time. In addition, eligibility or
          suitability requirements may apply for access to this information or
          any transaction with or regarding the Affiliated Entities and they may
          not be available in all geographic areas.
        </p>
        <h3>3. WEBSITE ACCURACY</h3>
        <p>
          Although we have tried to provide accurate and timely information on
          the Website, you acknowledge and agree that the Website (including
          without limitation the Content) may not always be entirely accurate,
          complete, or current, and may also include technical inaccuracies or
          typographical errors. The Content and other information published on
          the Website is provided as a convenience and is provided for general
          informational purposes only. Accordingly, we do not warrant the
          accuracy, completeness, or usefulness of the Website. Any reliance you
          place on the Website (including without limitation the Content) is
          strictly at your own risk. We disclaim all liability and
          responsibility arising from any reliance placed on such materials by
          you or any other visitor to the Website, or by anyone who may be
          informed of any of its Content. You should verify all information
          before relying on it, and all decisions based on any Content or other
          information contained in or offered on or through the Website are your
          sole responsibility and we shall have no liability for such decisions.
          If you need specific details about any Content or other information
          contained in or offered on or through the Website, you should contact
          us as provided in the “Contact Us” section of the Website.
        </p>
        <h3>4. ACCESSING THE WEBSITE AND ACCOUNT SECURITY</h3>
        <p>
          We reserve the right to withdraw or amend the Website, and any service
          or material we provide on the Website, in our sole discretion without
          notice. We will not be liable if for any reason all or any part of the
          Website is unavailable at any time or for any period. From time to
          time, we may restrict access to some parts of the Website, or the
          entire Website. You are responsible for: (1) making all arrangements
          necessary for you to have access to the Website; and (2) ensuring that
          all persons who access the Website through your internet connection
          are aware of these Terms and Conditions and comply with them.
        </p>
        <p>
          To access the Website or some of the resources it offers, you may be
          asked to provide certain registration details or other information. It
          is a condition of your use of the Website that all the information you
          provide on the Website is correct, current, and complete. You agree
          that all information you provide to register with the Website or
          otherwise is governed by the Privacy Policy and you consent to all
          actions we take with respect to your information consistent with the
          Privacy Policy.
        </p>
        <p>
          If you choose, or are provided with, a user name, password, or any
          other piece of information as part of our or the Website’s security
          procedures, you must treat such information as confidential, and you
          must not disclose it to any other person or entity. You also
          acknowledge that your account is personal to you and agree not to
          provide any other person or entity with access to the Website or
          portions of it using your user name, password, or other security
          information. You agree to notify us immediately of any unauthorized
          access to or use of your user name or password or any other breach of
          security. You also agree to ensure that you exit from your account at
          the end of each session. You should use particular caution when
          accessing your account from a public or shared computer so that others
          are not able to view or record your password or other personal
          information.
        </p>
        <p>
          We have the right to terminate or suspend your account, and/or disable
          any user name, password, or other identifier, whether chosen by you or
          provided by us, at any time in our sole discretion for any reason or
          no reason, including if, in our opinion, you have violated any
          provision of these Terms and Conditions. You agree that the Affiliated
          Entities shall not be liable to you or any third party for termination
          of your access to or use of the Website (or any part thereof).
        </p>
        <h3>5. WEBSITE RESTRICTIONS</h3>
        <p>
          As a condition of your use of the Website, you represent and warrant
          that you shall not use the Website for any purpose that is unlawful or
          that is prohibited by these Terms and Conditions. You will not submit
          any false, misleading, or inaccurate information to the Website. You
          will abide by all applicable local, state, national, and international
          laws and regulations, and you shall be solely responsible and liable
          for all of your acts or omissions that occur while you use the
          Website. By ways of example, and not as a limitation, you will not use
          the Website to: (a) defame, abuse, harass, stalk, threaten, or
          otherwise violate or infringe the legal rights (such as, but not
          limited to, rights of privacy, publicity, and intellectual property)
          of others; (b) publish, distribute, or disseminate any harmful,
          inappropriate, profane, vulgar, infringing, obscene, tortious,
          indecent, immoral, or otherwise objectionable material or information;
          (c) transmit or upload any material to the Website that contains
          viruses, trojan horses, worms, time bombs, cancelbots, or any other
          harmful or deleterious programs; (d) interfere with or disrupt the
          Website networks or servers; (e) harvest or otherwise collect
          information from the Website about others, including without
          limitation email addresses, without proper consent; (f) use the
          account, login identification, or password of another party to access
          the Website; (g) otherwise attempt to gain unauthorized access to the
          Website, other accounts, or computer systems or networks connected to
          the Website, through password mining or any other means; (h) interfere
          with, or engage in any conduct that restricts or inhibits, another
          individual’s or entity’s use or enjoyment of the Website, or which
          conduct, as determined by us, may harm us or any of the Affiliated
          Entities, or users of the Website, or expose them to liability; or (i)
          impersonate or attempt to impersonate us, any of our employees,
          another user, or any other person or entity (including, without
          limitation, by using e-mail addresses or user names associated with
          any of the foregoing).
        </p>
        <p>
          We have no obligation to monitor your use of the Website or retain the
          content of any of your sessions on the Website. By transmitting
          information through the Website, you accept the risk that such
          information as well as any information that we transmit to you, may be
          intercepted by third parties. In addition, messages that you send to
          us by e-mail may not be secure. If you choose to send any confidential
          information to us via e-mail, you accept the risk that this
          information may be intercepted by a third party. We reserve the right
          at all times to monitor, review, retain, and/or disclose any
          information as necessary to satisfy any applicable law, regulation,
          legal process, or governmental request or as otherwise may be legally
          permissible.
        </p>
        <h3>6. DISCLAIMER OF WARRANTIES</h3>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          information and files available for viewing or downloading (if
          applicable) from the internet or the Website will be free of viruses
          or other destructive code. You are responsible for implementing
          sufficient procedures and checkpoints to satisfy your particular
          requirements for anti-virus protection and accuracy of data input and
          output, and for maintaining a means external to the Website for any
          reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR
          DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
          OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL AVAILABLE ON OR
          THROUGH IT, OR ON OR THROUGH ANY LINKED SITE OR OTHER WEBSITE.
        </p>
        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED ON OR THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE,
          ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED ON OR THROUGH THE
          WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT
          ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER WE NOR
          ANY OF THE AFFILIATED ENTITIES OR ANY PERSON ASSOCIATED WITH US OR
          THEM MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
          AVAILABILITY OF THE WEBSITE (INCLUDING, WITHOUT LIMITATION, THE
          CONTENT). WITHOUT LIMITING THE FOREGOING, NEITHER WE NOR ANY OF THE
          AFFILIATED ENTITIES OR ANYONE ASSOCIATED WITH US OR THEM REPRESENTS OR
          WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS
          OBTAINED ON OR THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
          ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
          WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED ON OR THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
          EXPECTATIONS
        </p>
        <p>
          WE HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
          WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
          PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <h3>7. LINKS WITH OTHER WEBSITES</h3>
        <p>
          We may provide links to other websites, content, and/or resources
          provided by other users, third-party licensors and service providers,
          aggregators, reporting services, and other third parties (“Linked
          Sites”). If you decide to access any of the Linked Sites, you do so
          entirely at your own risk and subject to the terms and conditions of
          use for such Linked Sites. You acknowledge and agree that we have no
          control over and is not responsible for the Linked Sites and any
          resources, products, services, functionality, information, content, or
          other materials provided on or through such Linked Sites. All
          statements and/or opinions expressed on or through the Linked Sites
          and in any such third party materials are solely the opinions and the
          responsibility of the person or entity providing those materials.
          Information and materials provided on or through the Linked Sites do
          not necessarily reflect our opinion. Moreover, our reference to or use
          of tangible or intangible property, a product, service, or process
          does not imply our ownership, recommendation, approval, affiliation,
          or sponsorship of that respective property, product, service, or
          process. You agree that neither we nor any of the Affiliated Entities
          shall be responsible or liable, directly or indirectly, for any damage
          or loss arising out of or relating to the Linked Sites, including
          without limitation content, property, goods, or services available on
          or through the Linked Sites. Links to the Website are not permitted
          without our express prior permission.
        </p>
        <h3>8. INDEMNIFICATION</h3>
        <p>
          You agree to defend, indemnify, and hold the Affiliated Entities,
          their respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns, harmless from and
          against any and all claims, liabilities, damages, judgments, awards,
          losses, costs, expenses, or fees, including without limitation,
          reasonable attorneys’ fees, arising out of or relating to your use of
          the Website and/or of any information obtained from the Website,
          including without limitation arising out of or relating to your
          violation of these Terms and Conditions, any applicable laws, rules,
          and regulations, or your violation of any rights of a third party.
        </p>
        <h3>9. LIMITATION OF LIABILITY</h3>
        <p>
          IN NO EVENT WILL WE OR ANY OF THE OTHER AFFILIATED ENTITIES, OR OUR OR
          THEIR RESPECTIVE LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
          OFFICERS, OR DIRECTORS, BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
          LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
          INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
          ON THE WEBSITE OR SUCH OTHER WEBSITES, OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS, OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS AND CONDITIONS OR THE WEBSITE MUST BE COMMENCED WITHIN
          ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE
          OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <h3>
          10. MODIFICATIONS TO THESE TERMS AND CONDITIONS; CHANGES TO THE
          WEBSITE
        </h3>
        <p>
          We may revise, update, and otherwise change these Terms and Conditions
          from time to time in our sole discretion. We will provide notice of
          material updates to these Terms and Conditions by placing a banner
          notice on the Website for a reasonable period of time. Your continued
          use of the Website following the posting of the revised version of
          these Terms and Conditions means that you accept and agree to the
          changes. You are expected to check this page of the Website frequently
          so you are aware of any changes, as they are binding on you. We
          reserve the right to modify or temporarily discontinue your access to
          the Website or any portions or parts thereof, with or without notice
          to you. You agree that we shall not be liable to you or any
          third-party for any modification to the Website or your access to the
          Website.
        </p>
        <p>
          We may update the content on the Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>
        <p>
          IN ADDITION, IF YOU HAVE AN ACCOUNT WITH US, YOUR CUSTOMER
          RELATIONSHIP IS GOVERNED BY OTHER AGREEMENTS.
        </p>
        <h3>11. INTERNET ACCESS</h3>
        <p>
          To use the Website, you must at no cost to us: (1) provide for your
          own access to the World Wide Web and pay any service fees associated
          with such access; and (2) provide all software, hardware, and
          equipment necessary for you to make such connection to the World Wide
          Web, including without limitation a computer, modem, and broadband
          Internet connection.
        </p>
        <h3>12. INFORMATION RELATING TO FUND SECURITIES</h3>
        <p>
          Certain of the information contained on the Website may relate to
          products distributed by us and/or other Affiliated Entities (the
          “Affiliate Funds”) who have made or currently make public offerings of
          their securities. Securities of available Affiliate Funds may be
          offered by us as dealer manager, Member FINRA/SIPC. Unless otherwise
          expressly stated, the properties depicted on the Website are for
          illustrative purposes and are not owned, or intended to be acquired,
          by any of the Affiliate Funds. Except as otherwise disclosed in their
          respective prospectuses as amended, the Affiliate Funds have not yet
          identified any properties for acquisition. There can be no assurance
          the funds’ investment objectives will be achieved. A copy of the
          current version of each of the available Affiliate Funds’ prospectuses
          is available on the Website. For those considering an investment in
          the Affiliate Funds, the Website must be read in conjunction with the
          applicable prospectus in order to fully understand all the
          implications and risks of the respective offering of the securities to
          which it relates. For additional prospectuses, please contact us using
          the applicable contact information posted on the “Contact Us” (or
          similar) page of the Website. An investment in the securities of the
          Affiliate Funds is subject to substantial risks. These risks include
          absence of a public market for these securities, lack of an operating
          history, absence of properties identified for acquisition, limited
          transferability and lack of liquidity, possibility of substantial
          delay before distributions are made, reliance on the fund’s general
          partners or advisor, payment of significant fees to the general
          partners or advisor and their affiliates, potential conflicts of
          interest, and lack of diversification in property holdings until
          significant funds have been raised. An investment in the securities of
          the Affiliate Funds is not suitable for all investors. Refer to the
          applicable prospectus for a more detailed discussion of risks. The
          Website is neither an offer to sell nor the solicitation of an offer
          to buy any security, which can be made only by the prospectus, filed
          or registered with appropriate state and federal regulatory agencies,
          and sold by broker/dealers authorized to do so. No regulatory agency
          has passed on or endorsed the merits of the Affiliate Funds. Any
          representation to the contrary is unlawful. Consult the prospectus and
          subscription document for suitability standards in your state. NEITHER
          THE ATTORNEY GENERAL OF THE STATE OF NEW YORK NOR ANY OTHER STATE HAS
          PASSED ON OR ENDORSED THE MERITS OF ANY OFFERING OF THE AFFILIATE
          FUNDS. ANY REPRESENTATION TO THE CONTRARY IS UNLAWFUL.
        </p>
        <h3>13. TERMINATION</h3>
        <p>
          You agree that the Affiliated Entities shall not be liable to you or
          any third party for termination of these Terms and Conditions and/or
          any termination or suspension of your access to the Website. Should
          you object to any terms or conditions of these Terms and Conditions or
          any subsequent modifications to these Terms and Conditions or become
          dissatisfied with the Website or any part of the Website in any way,
          your sole and exclusive remedy is to immediately: (1) terminate your
          use of the Website; and (2) if you are a registered user of the
          Website, notify us in writing of your termination of your agreement to
          these Terms and Conditions. Upon any termination or suspension of your
          account by us, or otherwise upon notice or action by us, your license
          rights to the Website immediately cease.
        </p>
        <h3>14. ARBITRATION</h3>
        <p>
          YOU ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT
          OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS ACTION OR
          REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU
          WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE
          LIMITED IN ARBITRATION.
        </p>
        <p>
          ANY CLAIM, DISPUTE, OR CONTROVERSY (WHETHER IN CONTRACT, TORT, OR
          OTHERWISE, WHETHER PRE-EXISTING, PRESENT, OR FUTURE, AND INCLUDING
          STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT,
          INJUNCTIVE, AND EQUITABLE CLAIMS) BETWEEN YOU AND US OR ANY OF THE
          OTHER AFFILIATED ENTITIES ARISING FROM OR RELATING IN ANY WAY TO YOUR
          USE OF THE WEBSITE (INCLUDING WITHOUT LIMITATION THE CONTENT), WILL BE
          RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
        </p>
        <p>
          Accordingly, you agree that any dispute arising in connection with
          these Terms and Conditions or the performance of any party under these
          Terms and Conditions or otherwise relating to these Terms and
          Conditions shall be referred to binding arbitration via the Financial
          Industry Regulatory Authority, Inc. (“FINRA”) arbitration process then
          in effect, except as modified by these Terms and Conditions.
          Arbitration uses a neutral arbitrator instead of a judge or jury,
          allows for more limited discovery than in court, and is subject to
          very limited review by courts. Arbitrators can award the same damages
          and relief that a court can award. The arbitrator will have exclusive
          authority to resolve any dispute relating to arbitrability and/or
          enforceability of this arbitration provision, including any
          unconscionability challenge or any other challenge that the
          arbitration provision or the agreement is void, voidable, or otherwise
          invalid. The arbitrator will be empowered to grant whatever relief
          would be available in court under law or in equity. The arbitration
          shall be binding, final, not appealable, enforceable, and in lieu of
          any right to sue or seek other arbitration in any court or tribunal.
          Notwithstanding the foregoing, either you or any of the Affiliated
          Entities shall be entitled to apply to any court of competent
          jurisdiction for a temporary restraining order, preliminary
          injunction, permanent injunction, or other equitable relief, without
          breach of this arbitration provision.
        </p>
        <p>
          This arbitration clause shall survive any termination of these Terms
          and Conditions. If this specific provision is found to be
          unenforceable, then (i) the entirety of this arbitration provision
          shall be null and void, but the remaining provisions of these Terms
          and Conditions shall remain in full force and effect; and (ii)
          exclusive jurisdiction and venue for any claims will be as set forth
          below.
        </p>
        <h3>15. EQUITABLE RELIEF</h3>
        <p>
          You acknowledge that any use or threatened use of the Website or
          Content in a manner inconsistent with these Terms and Conditions would
          cause immediate irreparable harm to us and/or the other Affiliated
          Entities for which there is no adequate remedy at law. Accordingly,
          you acknowledge and agree that we and the other Affiliated Entities
          shall be entitled to immediate and permanent injunctive relief from a
          court of competent jurisdiction in the event of any such breach or
          threatened breach by you. You agree and stipulate that we and the
          other Affiliated Entities shall be entitled to such injunctive relief
          without posting a bond or other security; provided however that if the
          posting of a bond is a prerequisite to obtaining injunctive relief,
          then a bond in the amount of One Thousand U.S. Dollars (USD $1,000)
          shall be sufficient. Nothing contained herein shall limit our right or
          the right of any of the other Affiliated Entities to any remedies at
          law, including without limitation the recovery of damages from you for
          breach of these Terms and Conditions, as applicable.
        </p>
        <h3>16. ASSIGNMENT</h3>
        <p>
          YOU WILL NOT ASSIGN, INCLUDING WITHOUT LIMITATION BY OPERATION OF LAW,
          CHANGE OF CONTROL, OR OTHERWISE, YOUR RIGHTS OR LICENSES TO THE
          WEBSITE PROVIDED UNDER THESE TERMS AND CONDITIONS, EITHER IN WHOLE OR
          IN PART WITHOUT OUR PRIOR WRITTEN CONSENT. WE SHALL HAVE THE RIGHT TO
          ASSIGN THESE TERMS AND CONDITIONS WITHOUT YOUR CONSENT AND WITHOUT
          NOTICE TO YOU. ANY ATTEMPT TO ASSIGN THESE TERMS AND CONDITIONS
          CONTRARY TO THIS SECTION WILL BE VOID AND HAVE NO EFFECT.
        </p>
        <h3>17. COPYRIGHT, TRADE NAMES, AND INTELLECTUAL PROPERTY RIGHTS</h3>
        <p>
          THE WEBSITE, ITS ENTIRE FEATURES AND FUNCTIONALITY, AND ALL OF THE
          INFORMATION ON THE WEBSITE, INCLUDING TEXT, IMAGES, GRAPHICS, AND
          SOFTWARE, IS OUR PROPERTY, OR THE PROPERTY OF OUR LICENSORS OR OTHER
          PROVIDERS OF SUCH MATERIAL, EXCEPT AS OTHERWISE INDICATED, AND IS
          PROTECTED BY UNITED STATES AND INTERNATIONAL COPYRIGHT, TRADEMARK,
          PATENT, AND OTHER INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS LAWS. IN
          ADDITION, THE COLLECTION, ARRANGEMENT, AND ASSEMBLY OF CONTENT ON THE
          WEBSITE IS OUR EXCLUSIVE PROPERTY AND IS LIKEWISE PROTECTED BY
          COPYRIGHT AND OTHER INTELLECTUAL PROPERTY LAWS. YOU MAY VIEW,
          DOWNLOAD, AND REPRINT INFORMATION AND MATERIALS CONTAINED ON THE
          WEBSITE (I) SOLELY FOR INFORMATIONAL PURPOSES AND FOR NO OTHER
          PURPOSE, AND (II) PROVIDED THAT NEITHER THE MATERIALS, NOR ANY
          PROPRIETARY NOTICES THEREIN, ARE MODIFIED OR ALTERED IN ANY WAY.
        </p>
        <p>
          The Website features trade names, logos, and other trademarks and
          service marks that are the property of, or are licensed to, us or
          other Affiliated Entities. The Website may also include trademarks or
          service marks of third parties. All of these trademarks are the
          property of their owners and you agree not to use or display them in
          any manner without the prior written permission of the applicable
          trademark owner. You also agree not to use, copy, modify, or display
          any of these marks in any manner likely to cause confusion or in any
          manner that disparages or discredits us or any of the other Affiliated
          Entities.
        </p>
        <p>
          If you print, copy, modify, download, or otherwise use or provide any
          other person or entity with access to any part of the Website in
          breach of these Terms and Conditions, your right to use the Website
          will cease immediately and you must, at our option, return or destroy
          any copies of the materials you have made. No right, title, or
          interest in or to the Website or any content on the Website is
          transferred to you, and all rights not expressly granted are reserved
          by us. Any use of the Website not expressly permitted by these Terms
          and Conditions is a breach of these Terms and Conditions and may
          violate copyright, trademark, other intellectual property or
          proprietary rights laws, and other laws.
        </p>
        <h3>18. NOTICE</h3>
        <p>
          Any notice required or allowed under these Terms and Conditions shall
          be deemed properly given and effective: (a) upon (i) actual delivery,
          if delivery is by hand; (ii) upon receipt by the transmitting party of
          confirmation or answer back, if delivery is by telex, telegram, or
          facsimile; (iii) five (5) days after delivery into the regular mail,
          postage prepaid by registered or certified mail, return
          receipt-requested to the respective party at the following address; or
          (b) if you have provided an email address, to you immediately upon
          transmittal of an email to such email address.
        </p>
        <h3>19. GEOGRAPHIC RESTRICTIONS</h3>
        <p>
          We make no claims that the Website or any of its content is accessible
          or appropriate outside of the United States. Access to the Website may
          not be legal by certain persons or entities in certain countries. If
          you access the Website from outside the United States, you do so on
          your own initiative and are responsible for compliance with local
          laws.
        </p>
        <h3>20. GENERAL</h3>
        <p>
          THESE TERMS AND CONDITIONS AND THE RELATIONSHIP BETWEEN YOU AND US AND
          THE OTHER AFFILIATED ENTITIES SHALL BE GOVERNED BY THE LAWS OF THE
          STATE OF CALIFORNIA WITHOUT REGARD TO ITS CONFLICT OF LAW PROVISIONS.
          Subject to Section 14 (Arbitration), you agree to submit to the
          non-exclusive jurisdiction of the courts located within the state of
          California, provided that any lawsuits or other claims brought by you
          must be brought exclusively within the state of California. You hereby
          consent and submit to the exclusive in personam jurisdiction of such
          courts, waive any objection based on forum non conveniens and waive
          any objection to venue of any action instituted hereunder to the
          extent that an action is brought in the courts identified above. The
          failure of us to exercise or enforce any right or provision of these
          Terms and Conditions shall not constitute a waiver of such right or
          provision. If any provision of these Terms and Conditions is found by
          a court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should endeavor to give effect to
          the parties’ intentions as reflected in the provision, and the other
          provisions of these Terms and Conditions remain in full force and
          effect. The section titles in these Terms and Conditions are for
          convenience only and have no legal or contractual effect. We shall not
          be liable for delays or failure to make the Website (including without
          limitation the Content) available if due to any cause or conditions
          beyond our reasonable control, including, but not limited to, delays
          or failures due to acts of God, acts of civil or military authority,
          fire, flood, earthquake, strikes, wars, failure of the Internet
          backbone, or shortage of power. We agree to make the Website available
          hereunder as an independent contractor, and in no event shall our
          employees and/or agents or the employees and/or agents of any of the
          other Affiliated Entities be deemed your employees and/or agents, and
          neither shall you and/or your employees and agents be deemed our
          employees and/or agents or the employees and/or agents of any of the
          other Affiliated Entities. Each party acknowledges that it is not
          entering into these Terms and Conditions on the basis of any
          representation not expressly contained in these Terms and Conditions.
          These Terms and Conditions constitutes the entire agreement between
          you and us (and the other Affiliated Entities) concerning this subject
          matter, and supersedes and cancels any and all prior or
          contemporaneous agreements or contracts, whether written or oral.
        </p>
        <h3>21. YOUR COMMENTS AND CONCERNS</h3>
        <p>
          All feedback, comments, requests for technical support, and other
          communications relating to the Website should be directed to us using
          the contact information provided in the “Contact Us” (or similar)
          section of the Website.
        </p>
      </div>
    </>
  );
};

export default TOU;
