import React, { useReducer, createContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

// css
import "./App.css";

// components
import MegaMenu from "./components/nav/MegaMenu";
// import Home from "./components/home/Home";
// import LandingPage from "./components/landing-page/LandingPage";
import Contact from "./components/contact/Contact";
import InvestmentManagement from "./components/what-we-do/InvestmentManagement";
import WholesaleDistribution from "./components/what-we-do/WholesaleDistribution";
import TechnologyOperations from "./components/what-we-do/TechnologyOperations";
import LoginPage from "./components/login/LoginPage";
import Footer from "./components/footer/Footer";
// import NotFound from "./NotFound";
import ScrollToTop from "./components/wrappers/ScrollToTop";

// wrappers
import AboutUsWrapper from "./components/wrappers/AboutUsWrapper";
import WhatWeDoWrapper from "./components/wrappers/WhatWeDoWrapper";

// Auth Context will pass the auth state from this component to any other component that needs it.
export const AuthContext = createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

function App() {
  const password = process.env.REACT_APP_PASS;
  const envType = process.env.REACT_APP_TYPE;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const token = sessionStorage.getItem("userpass");

    if (token && token === password) {
      dispatch({
        type: "LOGIN",
        payload: {
          token,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const renderLogin = () => {
    if (envType === "test") {
      return (
        <HashRouter>
          <AuthContext.Provider value={{ state, dispatch }}>
            {!state.isAuthenticated ? null : <MegaMenu />}
            <ScrollToTop>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    !state.isAuthenticated ? <LoginPage /> : <AboutUsWrapper />
                  }
                />
                <Route
                  exact
                  path="/aboutus"
                  element={
                    !state.isAuthenticated ? <LoginPage /> : <AboutUsWrapper />
                  }
                />
                <Route
                  exact
                  path="/what-we-do"
                  element={
                    !state.isAuthenticated ? <LoginPage /> : <WhatWeDoWrapper />
                  }
                />
                <Route
                  exact
                  path="/investment-management"
                  element={
                    !state.isAuthenticated ? (
                      <LoginPage />
                    ) : (
                      <InvestmentManagement />
                    )
                  }
                />
                <Route
                  exact
                  path="/wholesale-distribution"
                  element={
                    !state.isAuthenticated ? (
                      <LoginPage />
                    ) : (
                      <WholesaleDistribution />
                    )
                  }
                />
                <Route
                  exact
                  path="/technology-and-operations"
                  element={
                    !state.isAuthenticated ? (
                      <LoginPage />
                    ) : (
                      <TechnologyOperations />
                    )
                  }
                />
                <Route
                  exact
                  path="/contact-us"
                  element={!state.isAuthenticated ? <LoginPage /> : <Contact />}
                />
              </Routes>
            </ScrollToTop>
            {!state.isAuthenticated ? null : <Footer />}
          </AuthContext.Provider>
        </HashRouter>
      );
    } else {
      return (
        <HashRouter>
          <MegaMenu />
          <ScrollToTop>
            <Routes>
              <Route exact path="/" element={<AboutUsWrapper />} />
              <Route exact path="/aboutus" element={<AboutUsWrapper />} />
              <Route exact path="/what-we-do" element={<WhatWeDoWrapper />} />

              <Route
                exact
                path="/investment-management"
                element={<InvestmentManagement />}
              />
              <Route
                exact
                path="/wholesale-distribution"
                element={<WholesaleDistribution />}
              />
              <Route
                exact
                path="/technology-and-operations"
                element={<TechnologyOperations />}
              />
              <Route exact path="/contact-us" element={<Contact />} />
            </Routes>
          </ScrollToTop>
          <Footer />
        </HashRouter>
      );
    }
  };

  return <>{renderLogin()}</>;
}

export default App;
