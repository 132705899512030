import React, { useState } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

// assets
import navLogo from "../../assets/images/STRATCAP-LOGO.png";

// css
import "./MegaMenu.css";

const MegaMenu = () => {
  // state objects
  const [mainOpen, setMainOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const menuGrid = () => {
    return (
      <div className="menu-grid">
        <div className="menu-grid-column">
          <h3>About Us</h3>
          <ul className="nav-ul">
            <li>
              <NavLink
                to="/aboutus/#overview"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                History
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/aboutus/#mission"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Mission, Vision and Values
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/aboutus/#companies"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                The StratCap Companies
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/aboutus/#bythenumbers"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                By The Numbers
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/aboutus/#leadership"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Leadership & Team
              </NavLink>
            </li> */}
          </ul>
        </div>
        <div className="menu-grid-column">
          <h3>What We Do</h3>
          <ul className="nav-ul">
            <li>
              <NavLink
                to="/investment-management"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Investment Management
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/wholesale-distribution"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Wholesale Distribution
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/technology-and-operations"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Technology & Operations
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/what-we-do/#overview"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Overview
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/what-we-do/#proprietary-institutional-solutions"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Investment Solutions
              </NavLink>
            </li> */}
          </ul>
        </div>
        <div className="menu-grid-column">
          <h3>Sector Focus</h3>
          <ul className="nav-ul">
            <li>
              <NavLink
                to="/what-we-do/#wireless-infrastructure"
                smooth
                activeClassName="selected"
                // scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Wireless Infrastructure
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/what-we-do/#data-center-real-estate"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Data Center Real Estate
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/what-we-do/#digital-mobility"
                activeClassName="selected"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMainOpen(false);
                  setIsChecked(false);
                }}
              >
                Digital Mobility
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="menu-grid-column">
          <NavLink
            to="/contact-us"
            onClick={() => {
              setMainOpen(false);
              setIsChecked(false);
            }}
            className="menu-contact-link"
          >
            Contact Us
          </NavLink>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="nav-wrapper">
        <Link
          to="/"
          className="nav-icon"
          onClick={() => {
            setMainOpen(false);
            setIsChecked(false);
            scrollTop();
          }}
        >
          <img src={navLogo} alt="nav logo" />
        </Link>
        <input
          className="menu-btn"
          type="checkbox"
          id="menu-btn"
          name="menu-btn"
          checked={isChecked}
        />
        <label
          className="menu-icon"
          for="menu-btn"
          onClick={() => {
            setMainOpen(!mainOpen);
            setIsChecked(!isChecked);
          }}
        >
          <span className="navicon" aria-label="Hamburger menu 'icon'" />
        </label>
      </div>
      <div
        className={
          mainOpen ? "nav-dropdown-main-show" : "nav-dropdown-main-hide"
        }
      >
        <div className="nav-dropdown">{menuGrid()}</div>
        {/* <div className="nav-dropdown">{collapsableMen()}</div> */}
      </div>
    </>
  );
};

export default MegaMenu;
