import React from "react";

// css
import "./About-us.css";

const Mission = () => {
  return (
    <section className="mission" id="mission">
      <div class="main-padding mission-container">
        <div class="main-margin mission-main-wrapper">
          <div className="main-header-margin">
            <h1 className="main-header">Our Commitment to Excellence</h1>
            <p>
              We are a performance-driven investment management platform guided
              by a clear mission, a defined vision and a strong set of values.
            </p>
          </div>
        </div>
      </div>
      <div class="mission-bottom-container">
        <div class="main-margin mission-content-wrapper">
          <div class="card-grid">
            <div class="grid-card">
              <h1>Mission</h1>
              <p>
                We are committed to providing access to dynamic asset classes
                and highly-experienced investment professionals in order to
                provide our partners with attractive risk-adjusted returns and
                positive investment experiences.
              </p>
            </div>
            <div class="grid-card vision-card">
              <h1>Vision</h1>
              <p>
                To be one of the leading investment managers of choice for
                alternative strategies.
              </p>
            </div>
            <div class="grid-card">
              <h1>Values</h1>
              <p>
                We believe in being transparent, accessible and doing what is
                right for our investors every step of the way. We lead with
                optimism, embrace diversity, treat employees and investors with
                utmost respect, and are socially and environmentally conscious.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
