import React, { useState } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
// import { Link } from "react-router-dom";

// components
import CustomModal from "../custom-modal/CustomModal";

// css
import "./Footer.css";

// assets
import logoImg from "../../assets/images/logo1-default.png";

const Footer = () => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 0;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <section class="main-padding footer-wrapper">
      <div class="main-margin">
        <div class="footer-content-container">
          <div className="footer-top-section">
            <div className="footer-logo-wrapper">
              <img src={logoImg} alt="stratcap logo" />
            </div>
            <div className="footer-links">
              <NavLink
                to="/contact-us/#contact-page"
                scroll={(el) => scrollWithOffset(el)}
                className="footer-links-header"
              >
                Contact Us
              </NavLink>
              <NavLink
                to="/contact-us/#contact-page"
                scroll={(el) => scrollWithOffset(el)}
                className="footer-links-a"
              >
                FINANCIAL ADVISORS
              </NavLink>
              <NavLink
                to="/contact-us/#contact-page"
                scroll={(el) => scrollWithOffset(el)}
                className="footer-links-a"
              >
                INVESTORS
              </NavLink>
              <NavLink
                to="/contact-us/#contact-page"
                scroll={(el) => scrollWithOffset(el)}
                className="footer-links-a"
              >
                INSTITUTIONAL SERVICES
              </NavLink>
              <NavLink
                to="/contact-us/#contact-page"
                scroll={(el) => scrollWithOffset(el)}
                className="footer-links-a"
              >
                BUY, SELL OR LEASE
              </NavLink>
            </div>
          </div>
          <div className="footer-middle-section">
            <p>
              Copyright StratCap 2021. All Rights Reserved. Investment Products:
              NOT FDIC INSURED | MAY LOSE VALUE | NOT BANK GUARANTEED.
            </p>
            <p>
              StratCap, LLC (“StratCap”) is headquartered in Newport Beach, CA.
              StratCap Investment Management, LLC (“StratCap Investment
              Management”) is headquartered in Greenwich, CT. StratCap
              Securities, LLC (“StratCap Securities”) is a broker-dealer
              registered with the SEC and a member of the Financial Industry
              Regulatory Authority, Inc. (“
              <a
                href="https://www.finra.org"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-disc-link"
              >
                FINRA
              </a>
              ”) headquartered in Newport Beach, CA. StratCap
              Securities is the distributor for StratCap Investment Management
              investment products, and any content relating to those investment
              products is the sole responsibility of StratCap Securities.
            </p>
            <p>
              The information provided herein is not directed at any investor or
              category of investors and is provided solely as general
              information about our products and services and to otherwise
              provide general investment education. No information contained
              herein should be regarded as a suggestion to engage in or refrain
              from any investment-related course of action as none of StratCap
              nor any of its affiliates is undertaking to provide investment
              advice, act as an adviser to any plan or entity subject to the
              Employee Retirement Income Security Act of 1974, as amended,
              individual retirement account or individual retirement annuity, or
              give advice in a fiduciary capacity with respect to the materials
              presented herein. If you are an individual retirement investor,
              contact your financial advisor or other fiduciary unrelated to
              StratCap about whether any given investment idea, strategy,
              product or service described herein may be appropriate for your
              circumstances. Check the background of StratCap Securities on{" "}
              <a
                href="https://brokercheck.finra.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-disc-link"
              >
                FINRA's BrokerCheck
              </a>
              .
            </p>
          </div>
          <div className="footer-bottom-section">
            <span className="t-small t-lgray">©2021 StratCap</span>
            <div className="pp-tou-wrapper">
              <span
                className="t-7em t-lgray c-pointer"
                onClick={() => {
                  setShow(true);
                  setType("BCP");
                }}
              >
                BCP{" "}
              </span>
              <span className="t-7em t-lgray"> | </span>
              <span
                className="t-7em t-lgray c-pointer"
                onClick={() => {
                  setShow(true);
                  setType("PP");
                }}
              >
                Privacy Policy{" "}
              </span>
              <span className="t-7em t-lgray"> | </span>
              <span
                className="t-7em t-lgray c-pointer"
                onClick={() => {
                  setShow(true);
                  setType("TOU");
                }}
              >
                {" "}
                Terms Of Use
              </span>
            </div>
          </div>
        </div>
      </div>
      <CustomModal type={type} show={show} hide={() => setShow(false)} />
    </section>
  );
};

export default Footer;
