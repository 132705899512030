import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// css
import "./WhatWeDo.css";

// assets
import exceptionalImg from "../../assets/images/Investment-Solutions-IMG.jpg";
import poserImg from "../../assets/images/SCIM.jpg";
import simVideo from "../../assets/videos/SCIM.mp4";
import mobileHeaderImg from "../../assets/images/SCIM-MOBILE.jpg";

const InvestmentManagement = () => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return (
    <>
      <section className="wwd-overview" id="investment-management">
        {/* <div class="main-padding wwd-overview-container">
          <div className="main-header-margin">
            <h1 className="main-header">StratCap Investment Management</h1>
          </div>
        </div> */}
        {/* TEST START */}
        <div className="video-header-container">
          <div className="video-header-content-container">
            {screenSize.dynamicWidth < 665 ? (
              <div>
                <img
                  src={mobileHeaderImg}
                  alt="stratcap advisory services header"
                  className="mobile-header-img"
                />
                <div className="header-video-text-overlay main-margin">
                  <div className="main-header-margin">
                    <h1 className="header-video-overlay-text">
                      StratCap Investment Management
                    </h1>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  poster={poserImg}
                  id="header-video"
                >
                  <source src={simVideo} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                <div className="header-video-text-overlay main-margin">
                  <div className="main-header-margin">
                    <h1 className="header-video-overlay-text">
                      StratCap Investment Management
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* TEST END */}
        <div class="main-padding wwd-solutions-container">
          <div class="main-margin negative-margin">
            <p className="t-left investment-management-card">
              StratCap Investment Management targets exceptional companies,
              resilient asset classes and dynamic market sectors poised to
              benefit from an ever-growing shift toward a more
              technology-enabled digital economy. We believe the secular growth
              trends associated with today’s digital revolution and the
              continued emergence of new technologies point to a long-growth
              runway and attractive investment opportunities for select market
              sectors and asset classes.
            </p>
          </div>
          <div className="main-margin wwd-solutions-main-wrapper">
            <div className="wwd-solutions-content-wrapper">
              <h1>Investment Solutions​</h1>
              <p>
                StratCap Investment Management prides itself on being able to
                custom tailor solutions for its clientele by strategically
                matching risk tolerances with return opportunities.
              </p>
              <p>
                Our highly skilled investment teams offer valuable insight and
                deep domain expertise in asset classes and industry sectors that
                are difficult to access outside of traditional public markets.
                Our teams have the experience and established relationships to
                seek out and exploit market inefficiencies via off-market
                transactions in order to create value for our investment
                partners.
              </p>
              {/* links to institutional team on contacts page, or opens general email to someone in the institutional team */}
              <div className="is-learn-button-wrapper">
                <Link to="/contact-us" className="link-button">
                  Contact Us to Learn More
                </Link>
              </div>
            </div>
            <div className="solutions-content-img">
              <img src={exceptionalImg} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestmentManagement;
