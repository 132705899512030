import React from "react";

// css
import "./WhatWeDo.css";

const DataCenter = () => {
  return (
    <section className="wwd-datacenter" id="data-center-real-estate">
      <div class="main-padding wwd-dc-container">
        <div class="main-margin wwd-dc-main-wrapper">
          <div className="main-header-margin">
            <h1 className="main-header dcre-header">Data Center Real Estate</h1>
            <p className="t-left">
              The relentless growth of the internet, wireless data, cloud
              computing, digital content, social media, and e-commerce continues
              to fuel significant demand for data center real estate.
            </p>
          </div>
        </div>
      </div>
      <div class="main-padding wwd-dc-bottom-wrapper">
        <div class="main-margin wwd-dc-content-wrapper">
          <div class="sector-focus-card-grid">
            <div className="sector-focus-grid-card">
              <div class="sector-focus-card-inside">
                <h1>Technology-Driven Real Estate</h1>
                <p>
                  We believe, data centers’ solid history of reliable
                  performance, combined with the continued growth of global
                  digitalization trends, makes this property type particularly
                  attractive.
                </p>
              </div>
            </div>
            <div className="sector-focus-grid-card">
              <div class="sector-focus-card-inside">
                <h1>What Are Data Centers?</h1>
                <p>
                  Data centers are highly sophisticated buildings that house and
                  operate mission critical computer operations and equipment.
                  They house the essential infrastructure that fuels everything
                  from shopping online and accessing apps to streaming movies
                  and working from home.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataCenter;
