import React, { useEffect } from "react";

// css
import "./Contact.css";

// assets
import contactImg from "../../assets/images/CONTACTUS-IMG.png";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="contact-us" id="contact-page">
      <div class="main-padding contact-us-container">
        <div class="main-margin contact-us-main-wrapper">
          <div className="main-header-margin">
            <h1 className="main-header">Contact Us</h1>
          </div>
        </div>
      </div>
      <div class="main-padding contact-us-bottom-wrapper">
        <div class="main-margin contact-us-content-wrapper">
          <p>
            We’re happy to discuss our solutions and services in more detail.
            See the contact information below to ensure your request makes it to
            the right team.
          </p>
          <div className="contact-us-content-grid">
            <div className="contact-links-section">
              <h3>FINANCIAL ADVISORS</h3>
              <a href="tel:877-907-1148">(877) 907-1148</a>
              <br />
              <a href="mailto:info@stratcapsecurities.com">
                info@stratcapsecurities.com
              </a>
              <a
                href="http://advisorportal.stratcap.com/"
                alt="advisor portal login"
                target="_blank"
                rel="noopener noreferrer"
                className="block contact-space-bot"
              >
                Advisor Portal Login
              </a>
              <h3>INVESTORS</h3>
              <a href="tel:888-292-3178">(888) 292-3178</a>
              <br />
              <a
                href="http://thestrategicfunds.com/"
                alt="Investor login"
                target="_blank"
                rel="noopener noreferrer"
                className="block contact-space-bot"
              >
                Investor Login
              </a>
              <h3>INSTITUTIONAL SERVICES</h3>
              <a href="tel:475-282-0861">(475) 282-0861</a>
              <br />
              <a href="mailto:info@stratcap.com">info@stratcap.com</a>
              <h3 className="contact-space-top">
                BUY, SELL OR LEASE
                <br /> DIGITAL INFRASTRUCTURE ASSETS
              </h3>
              <span class="block">Wireless Infrastructure Team</span>
              <a href="tel:475-282-0586">(475) 282-0586</a>
              <br />
              <a href="mailto:info@stratcapwireless.com">
                info@stratcapwireless.com
              </a>
              <br />
              <br />
              <span class="block">Data Center Real Estate Team</span>
              <a href="tel:475-282-0875">(475) 282-0875</a>
              <br />
              <a href="mailto:info@stratcapdatacenters.com">
                info@stratcapdatacenters.com
              </a>
            </div>
            <div className="contact-us-img-container">
              <img src={contactImg} alt="" className="contact-us-img"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
